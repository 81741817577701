/* You can add global styles to this file, and also import other style files */
/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification\ 
for details on configuring this project to bundle and minify static web assets. */
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

/* Fonts */
@font-face {
    font-family: DinPro;
    src: url(./assets/fonts/DINPro-Regular.otf);
}

@font-face {
    font-family: DinPro;
    src: url(./assets/fonts/DINPro-Bold.otf);
    font-weight: bold;
}

@font-face {
    font-family: DinPro;
    src: url(./assets/fonts/DINPro-Medium.ttf);
    font-weight: 400;
}

/* Colors*/
:root {
    --main-blue-color: #0071b9;
    --main-gray-color: gray;
    --main-black-color: black;
    --main-bg-color-black-box: rgba(0, 0, 0, 0.2);
    --main-bg-color-black-dark-box: rgba(0, 0, 0, 0.5);
    --main-bg-color-white-box: rgba(255, 255, 255, 0.5);
    --main-bg-color-blue-box: rgba(0, 113, 185, 0.7);
    --main-bg-color-blue-dark-box: rgba(0, 113, 185, 0.8);
    --main-bg-color-blue-light-box: rgba(0, 113, 185, 0.4);
}

html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    font-family: DinPro;
    font-weight: normal;
    min-height: 100%;
    background-image: url(./assets/images/star.png), url(./assets/images/background.jpg);
    background-size: 24%, 100% 100%;
    background-position: -7% 100%, center;
    /*Positioning*/
    background-repeat: no-repeat, no-repeat;
    /*Prevent showing multiple background images*/
    background-attachment: fixed;
}


nav {
    padding-top: 10px;
    position: fixed;
    top: 0px;
    width: 100%;
}

footer {
    padding-bottom: 10px;
    position: fixed;
    bottom: 0px;
    width: 100%;
}

.container {
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
}

.contact-chiled {
    float: right;
    height: 70vh;
}

#contact {
    padding-top: 20px;
}

#top {
    width: 100%;
    padding-top: 1%;
    padding-bottom: 30px;
}

#logo {
    width: 400px;
    height: auto;
}

.menu-bar {
    padding-top: 55px;
    font-size: 150%;
}

.main-image {
    display: block;
    margin: auto;
    position: relative;
    height: 500px;
    top: -151px;
    right: -250px;
    z-index: -1;
}

.main-image-box {
    height: 620px;
}

.main-message {
    height: 90vh;
    width: 90vh;
    position: fixed;
    right: -10vh;
    bottom: -25vh;
    border-radius: 50%;
    z-index: 1;
    display: table;
    color: white;
    font-size: 37px;
    padding-left: 10%;
    padding-right: 2%;
    padding-bottom: 10%;
}

.instructor-image {
    height: 40vh;
}

.instructor-row {
    margin-top: 50px;
}

.instructor-row-main {
    margin-top: 100px;
}

.level-dot {
    height: 20px;
    width: 20px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.about-text {
    height: 70vh;
}

#main-message-content {
    display: table-cell;
    vertical-align: middle;
}

.button-round {
    border-radius: 50%;
    border: none;
    color: white;
    width: 150px;
    height: 150px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    font-size: 26px;
    font-weight: bold;
}

.offer-nested-button-round {
    border-radius: 50%;
    border: none;
    color: white !important;
    width: 145px;
    height: 145px;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    display: table-cell !important;
    font-size: 26px;
    font-weight: bold;
}

.login-box {
    margin-top: 10vh;
    padding: 0px 2vw 0px 2vw;
    display: table;
    width: 40vh;
    height: 40vh;
    background-color: #24b8bc;
    border-radius: 50%
}

#login-form {
    display: table-cell;
    vertical-align: middle;
}

#login-submit-button {
    position: absolute;
    left: 30vw;
}

.dd {
    width: 50%;
}

.right {
    float: right;
}

.color-turquoise {
    background-color: #24b8bc;
}

.color-turquoise:hover {
    background-color: #018b8f;
}

.text-color-turquoise {
    color: #24b8bc !important;
}

.color-pink {
    background-color: #e52c8a;
}

.color-blue {
    background-color: #0071b9;
}

.color-pink:hover {
    background-color: #ac2167;
}

.color-blue:hover {
    background-color: #01558b;
}

.text-color-blue {
    color: #0071b9;
}

.main-text {
    font-weight: bold;
    font-size: 65px;
    margin-left: 50px;
}

.holiday-title {
    font-weight: bold;
    font-size: 29px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.center {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.holiday-logo {
    margin-top: 50px !important;
    height: 190px;
}

.box {
    max-width: 2060px;
    margin: auto;
}

.mobile-menu {
    background-color: #24b8bc;
    border-radius: 50%;
    border: none;
    width: 60px;
    height: 60px;
    margin-top: 19px;
    margin-left: 7px;
    margin-right: 0px;
}

.mobile-menu .icon-bar {
    display: block;
    margin: auto;
    width: 31px;
    height: 4px;
    border-radius: 3px;
}

.mobile-menu-bar {
    border-radius: 20%;
    width: 41px;
    height: 8px;
}

#menu {
    background-color: #ffffffa8;
    font-size: 15px;
    margin-bottom: 0;
}

.center-content {
    display: flex;
    justify-content: center;
}


.center-box {
    width: 100%;
    padding: 25px;
}

.white-box {
    background-color: var(--main-bg-color-white-box);
    margin: 0px 25px 10px 25px;
    padding: 5px 10px 5px 15px;
}

.white-box h4 {
    text-align: center;
}

.card-white-box {
    background-color: var(--main-bg-color-white-box);
    height: 100%;
    padding: 15px;
}

.borderless tr,
.borderless td,
.borderless th {
    border: none !important;
    text-align: center;
}


/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
    position: relative;
    display: flex;
    flex-grow: 1;
    padding-left: 0px;
    padding-right: 0px;
}

.flex {
    display: flex;
}

a {
    color: black;
}

// a:visited 
a:link {
    color: var(--main-blue-color);
    /*color: var(--main-black-color);*/
    text-decoration: none;
}

a:hover,
a:active {
    color: var(--main-black-color);
    text-decoration: none;
    font-weight: bold;
}

// a.navbar.active {
//     color: var(--main-blue-color);
//     text-decoration: none;
//     font-weight: bold;
// }

caption {
    color: var(--main-black-color);
}

footer {
    width: 100%;
    background: var(--main-bg-color-black-box);
    /*position: absolute;
    bottom: 0;*/
}

img.social-icon {
    width: 60px;
    padding-top: 20px;
}

img.lock {
    position: relative;
    height: 20px;
    bottom: 3px;
}

.jumpcamp-input {
    background-color: rgba(0, 0, 0, 0.1);
    border: none;
    height: 25px;
    outline: none;
}

.jumpcamp-button {
    text-transform: uppercase;
    border: none;
    background-color: transparent;
    color: black;
    outline: none;
    margin: 5px;
}

.jumpcamp-button.clicked {
    color: var(--main-blue-color);
    font-weight: bold;
}

.jumpcamp-button:hover {
    font-weight: bold;
}

.jumpcamp-button:focus {
    font-weight: bold;
    color: var(--main-blue-color);
}

.jumpcamp-table {
    margin-top: 30px;
    width: 100%;
    text-transform: uppercase;
    border-spacing: 5px;
}

.jumpcamp-table th {
    background-color: var(--main-bg-color-black-box);
    padding: 5px;
}

.jumpcamp-table thead>tr {
    border-bottom: 0px;
}

.jumpcamp-table td {
    padding: 5px;
}

.jumpcamp-table tr {
    height: 50px;
    border-bottom: 3px solid transparent;
    background-color: var(--main-bg-color-white-box);
    background-clip: padding-box;
    border-radius: 10px;
}

.cancelled {
    color: var(--main-gray-color) !important;
}

.first {
    background-color: var(--main-bg-color-blue-dark-box);
}

.first-light {
    background-color: var(--main-bg-color-blue-light-box);
}

/* Carousel */
.carousel-caption p {
    font-size: 20px;
    line-height: 1.4;
}

/* Make .svg files in the carousel display properly in older browsers */
.carousel-inner .item img[src$=".svg"] {
    width: 100%;
}

/* QR code generator */
#qrCode {
    margin: 15px;
}

/* Hide/rearrange for smaller screens */
@media screen and (max-width: 767px) {

    /* Hide captions */
    .carousel-caption {
        display: none;
    }
}

.offer-buttons {
    margin-top: 15vh;
}

.offer-image-container {
    margin-top: 15vh;
}

.offer-image {
    height: 300px;
    width: auto;
    margin-top: 20vh;
}

.offer-content {
    background-color: rgba(230, 230, 230, 0.66);
}

.offer-button-round {
    outline: 0;
    border-radius: 50%;
    border: none;
    color: white;
    width: 200px;
    height: 200px;
    text-align: center;
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
}

#test {
    display: grid;
}

#footer-content {
    padding-top: 20px;
}

.offer-nested-nav {
    margin-left: 15px;
    margin-right: 15px;
    display: inline-block !important;
}

.section {
    padding-left: 10px;
    padding-top: 20%;
    height: 100vh;
}

#main-message-content a {
    color: #24b8bc;
}

.mynavBar {
    margin: auto;
    padding-top: 35px;
}

.holiday-content {
    padding-top: 30px;
    padding-left: 25px;
}

.holiday-content b {
    font-size: 18px;
}

.holiday-img {
    height: 550px;
    margin: auto;
    display: block;
    padding-top: 30px;
}

.group {
    float: right;
}


@media only screen and (max-width: 1200px) {
    .menu-bar {
        padding-top: 0px;
        font-size: 110%;
    }

    .holiday-logo {
        margin-top: unset;
        height: 100px;
    }

    .holiday-img {
        display: block;
        height: 350px !important;
    }

    .holiday-content b {
        font-size: unset;
    }

    #logo {
        width: 220px;
        height: auto;
    }

    .contact-content {
        text-align: center;
    }

    .main-image {
        height: 24vh;
        position: unset;
        top: unset;
        left: unset;
    }

    .button-round {
        outline: 0;
        border-radius: 50%;
        border: none;
        color: white;
        width: 90px;
        height: 90px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        margin: 4px 2px;
        font-size: 17px;
        font-weight: bold;
    }

    .main-text {
        font-weight: bold;
        font-size: 22px;
        margin-left: unset;
    }

    .holiday-title {
        font-weight: bold;
        font-size: 22px;
    }

    .text-center {
        text-align: center;
    }

    .main-message {
        height: 100vw;
        width: 100vw;
        position: fixed;
        right: -10vh;
        bottom: -9vh;
        border-radius: 50%;
        z-index: 1;
        display: table;
        color: white;
        font-size: 18px;
        padding-left: 25%;
        padding-bottom: unset;
    }

    #main-message-content {
        display: table-cell;
        font-size: 15px;
        vertical-align: middle;
    }

    #footer-content {
        font-size: 7px;
        padding-top: 10px;
    }

    .no-padding {
        padding: 0 0 0 0;
    }

    img.social-icon {
        width: 35px;
        padding-top: 10px;
    }

    img.lock {
        position: unset;
    }

    .login-box {
        padding: 0px 10vw 0px 10vw;
    }

    #login-submit-button {
        bottom: -18vh;
        position: absolute;
        top: unset;
        left: 35%;
    }

    .offer-start-container {
        display: flex;
        flex-direction: column;
    }

    .offer-buttons {
        order: 1
    }

    .offer-image-container {
        margin-top: 0;
        order: 2
    }

    .offer-image {
        height: 92px;
        width: auto;
        margin-top: 1vh;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .offer-button-round {
        border-radius: 50%;
        border: none;
        color: white;
        width: 100px;
        height: 100px;
        text-align: center;
        text-decoration: none;
        font-size: 13px;
    }

    .offer-nested-button-round {
        border-radius: 50%;
        border: none;
        color: white !important;
        width: 45px;
        height: 45px;
        text-align: center;
        text-decoration: none;
        display: inline-block !important;
        margin: 4px 8px;
        font-size: 10px;
        font-weight: bold;
        padding: 10px 0 0 0 !important;
    }

    .offer-nested-nav {
        margin-left: 0px;
        margin-right: 0px;
        display: inline-block !important;
    }

    .row-sm-no-padding {
        padding: 0 !important;
    }

    .contact-chiled {
        float: unset;
        margin: auto;
        display: block;
        height: 50vh;
    }

    body {
        background-size: 60%, 100% 100%;
        background-position: -25% 100%, center;
    }

    .navbar-inverse .navbar-toggle:hover,
    .navbar-inverse .navbar-toggle:focus {
        background-color: #018b8f;
        ;
    }

    .login-box {
        margin-top: 20px !important;
        ;
        margin: auto;
    }

    .offer-content {
        font-size: 12px;
    }

    .holiday-content {
        padding-top: 30px;
        padding-left: 15px;
    }

    .offer-content h3 {
        font-size: 17px;
    }

    .section {
        padding-left: 10px;
        padding-top: unset;
        height: 100vh;
    }

    .mynavBar {
        margin: auto;
        padding-top: 10px;
    }

    .custom-form-control {
        height: 25px;
    }

    .about-text {
        height: 290px;
        position: relative;
        left: -40px;
    }

    .instructor-image {
        height: 150px;
        float: right;
    }

    .instructor-row-main {
        margin-top: 20px;
    }

    .dd {
        width: 100%;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
    }

    .navbar-nav {
        float: left;
    }

    .main-image-box {
        height: unset;
    }

}

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .navbar-header {
        float: none;
    }

    .navbar-left,
    .navbar-right {
        float: none !important;
    }

    .navbar-toggle {
        display: block;
    }

    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }

    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-nav {
        float: none !important;
        margin-top: 7.5px;
    }

    .navbar-nav>li {
        float: none;
    }

    .navbar-nav>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .collapse.in {
        display: block !important;
    }

    .main-message {
        right: -25vw;
        bottom: -25vw;
    }

    .group {
        float: unset;
    }

    .about-text {
        height: 400px;
    }

    .holiday-img {
        height: 400px;
    }

    .offer-image {
        height: 200px;
    }
}

.mynavBar a {
    background-color: #0071b9;
}


.offer-nested-button-round:hover {
    background-color: #0071b9 !important;
    color: white;
    text-decoration: none;
}

.mynavBar .active a {
    background-color: #24b8bc !important;
}

.style-2 {
    scrollbar-color: #018b8f #F5F5F5;
}

.style-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.style-2::-webkit-scrollbar {
    width: 12px;
}

.style-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #018b8f;
}

offer-page {
    display: flex;
    width: 100%;
}


@media only screen and (max-width: 767px) and (orientation: landscape) {
    body {
        background-size: 27%, 100% 100%;
        background-position: -10% 100%, center;
    }

    .main-message {

        height: 100vh;
        width: 100vh;
        position: fixed;
        bottom: -25vh;
        border-radius: 50%;
        z-index: 1;
        display: table;
        color: white;
        font-size: 18px;
        padding-left: 15%;
        padding-bottom: 10%;
    }

    .button-round {
        margin: auto;
        display: block;
    }

    .about-text {
        height: 386px;
        position: relative;
        left: -40px;
    }

    .instructor-image {
        float: unset;
        height: 175px;
        margin: auto;
        display: block;
    }

    .main-image {
        height: 45vh;
        position: unset;
        top: unset;
        left: unset;
    }

    #login-submit-button {
        bottom: 50px;
        left: 250px;
    }

    .login-box {
        margin-top: 10vh;
        padding: 30px;
        display: table;
        width: 250px;
        height: 250px;
        background-color: #24b8bc;
        border-radius: 50%;
    }


}

@media only screen and (max-width: 600px) {
    #logo {
        width: 220px;
        height: auto;
    }

    .contact-content {
        font-size: 12px;
        text-align: center;
    }

    .contact-content h3 {
        font-size: 17px;
    }

    .holiday-logo {
        margin-top: 30px !important;
    }
}

.navbar-inverse {
    border-color: transparent;
}

.navbar-inverse .navbar-nav a {
    color: #37383d;
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:hover,
.navbar-inverse .navbar-nav>.open>a:focus {
    color: unset;
    background-color: unset;
}

// .navbar-inverse .active .navbar-nav>li>a {
//     color: var(--main-blue-color);
//     text-decoration: none;
//     font-weight: bold;
// }

// a.nav-link:hover {
//     color: black !important;
//     text-decoration: none;
//     font-weight: bold;
// }

// a.active.nav-link {
//     color: var(--main-blue-color) !important;
//     text-decoration: none;
//     font-weight: bold;
// }


// .navbar-inverse .navbar-nav>li>a {
//     color: black;
//     text-decoration: none;
// }

// .navbar-inverse .navbar-nav .active>li>a {
//     color: black;
//     text-decoration: none;
//     font-weight: bold;
// }

.table-responsive {
    border: 0
}

.disabled-link {
    pointer-events: none;
    color: gray !important;
    /* Optional: Style to indicate the link is disabled */
    text-decoration: none;
    /* Optional: Remove underline to make it look less like a link */
}